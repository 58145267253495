import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

import {
  updateTopic,
  getTopics,
} from "../../redux/feature/topic/topic.service";
import { closeModal } from "../../redux/feature/modal/modal.slice";

const inputFields = [
  {
    id: "topic",
    type: "text",
    placeholder: "Enter Topic Name",
    label: "Topic Name",
  },
];

// Validation schema using Yup
const validationSchema = Yup.object({
  topic: Yup.string().required("Topic name is required"),
});

//--
const UpdateTopic = (props) => {
  const dispatch = useDispatch();

  const isUpdatingLoading = useSelector((state) => state?.loading[updateTopic.typePrefix]);

  const formik = useFormik({
    initialValues: {
      topic: props.item.topic || "",
    },

    validationSchema,

    onSubmit: (values, { resetForm }) => {
      console.log("updateTopic: FORM VALUES: ", values);

      // Dispatch the update action
      dispatch(updateTopic({
        id: props.item._id,
        data: {
          topic: values.topic,
        },
      }))
        .unwrap()
        .then((response) => {
          Swal.fire({
            title: "Success",
            text: "Topic updated successfully",
            icon: "success",
            confirmButtonText: "OK", // Add a confirm button with "OK" label
          }).then((result) => {
            if(result.isConfirmed) {
              dispatch(getTopics({page: props.currentPage, limit: props.itemsPerPage})); // Fetch updated data after success
              dispatch(closeModal()); // Close the modal only after successful update
              resetForm(); // Reset form after successful update
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error?.message,
            icon: "error",
            timer: 2000,
          });
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 gap-6 mt-4">
        {inputFields.map((field) => (
          <div key={field.id}>
            <label
              className="block text-[#464E5F] text-[14px] font-regular mb-2"
              htmlFor={field.id}
            >
              {field.label}
            </label>

            <input
              id={field.id}
              type={field.type}
              placeholder={field.placeholder}
              className={`p-3 bg-[#F3F6F9] text-[#B5B5C3] rounded-md w-full focus:outline-none ${
                formik.touched[field.id] && formik.errors[field.id]
                  ? "border border-red-500"
                  : ""
              }`}
              value={formik.values[field.id]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched[field.id] && formik.errors[field.id] ? (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors[field.id]}
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div className="flex justify-end items-center col-span-1 mt-8">
        <button
          type="submit"
          className="bg-[#2E8F96] text-white px-4 py-3 rounded-md hover:bg-[#26777E] transition duration-300 ease-in-out"
        >
          {isUpdatingLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  );
};

export default UpdateTopic;
