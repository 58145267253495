import Swal from "sweetalert2";
import api from "../../../utils/Api"
import { config } from "../../../utils/EndPoints"
import { createAsyncThunk } from "@reduxjs/toolkit";

const getOrders = createAsyncThunk("order/getOrders", async ({ page, limit, search = "" }, { rejectWithValue }) => {
  try {
    const response = await api.get(`${config.endPoints.getOrders}?page=${page}&limit=${limit}&search=${search}`)
    return response.data;

  } catch (error) {
     return rejectWithValue(error.message);
  }
});

const updateOrder = createAsyncThunk(
  'order/updateOrder', async ({ id, status } = {}, { rejectWithValue }) => {
    try {
      const response = await api.patch(`${config.endPoints.updateOrder}/${id}`, {status : status});
      if (response.status === 200) {
        Swal.fire('Updated', response?.data?.message, 'success');
      }
      return response?.data;

    } catch (error) {
      Swal.fire('Error', error?.response?.data?.message, 'error');
      return rejectWithValue(error.message);
    }
  }
);

const uploadResendDoc = createAsyncThunk('order/uploadDoc', async ({ orderId, formData } = {}, { rejectWithValue }) => {
  try {
    const response = await api.post(`${config.endPoints.uploadResendDoc}/${orderId}`, formData)
    return response?.data;

  } catch (error) {
      return rejectWithValue(error.message);
  }
})
 const getUserSingleOrder = createAsyncThunk('order/getSingleOrder', async (id, { rejectWithValue }) => {
  try {
    const response = await api.get(`${config.endPoints.getUserSingleOrder}/${id}`, { id : id });
    return response?.data;


  } catch (error) {
      console.log('Error Message:', error.message);
      return rejectWithValue(error.message);
  }
})
export { getOrders, updateOrder, uploadResendDoc, getUserSingleOrder};