import { createSlice } from "@reduxjs/toolkit";
import { getTopics, createTopic, deleteTopicById, updateTopic } from "./topic.service";

const initialState = {
  isLoading: false,
  topics: [],
  error: null,
};

const topicSlice = createSlice({
  name: "topics",
  initialState,
  extraReducers: (builder) => {
    builder
      // Fetch topics
      .addCase(getTopics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTopics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.topics = action.payload;
      })
      .addCase(getTopics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // Create topic
      .addCase(createTopic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTopic.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createTopic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // Update topic
      .addCase(updateTopic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTopic.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTopic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // Delete topic
      .addCase(deleteTopicById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTopicById.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTopicById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default topicSlice.reducer;
