import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "../pages/Auth/SignIn";
import OrderList from "../pages/OrderList/OrderList";
import UserList from "../pages/UserList/UserList";
import CreateLanguage from "../pages/language-price/CreateLanguage";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import PublicRoute from "../components/PublicRoute/PublicRoute";
import UploadFile from "../components/UploadFile/UploadFile";
import LanguageList from "../pages/language-price/LanguageList";
import OrderDetails from "../pages/OrderList/OrderDetails";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import TopicList from "../pages/topic/TopicList";


const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        {/* Public Route wrapped in PublicRoute.jsx -> It will restrict user to go back to signin page before logout */}
        <Route element={<PublicRoute />}>
          <Route path="/" element={<SignIn />} />
        </Route>
        <Route path="/send-password-reset-email" element={<ForgetPassword />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
        {/* <Route path="/upload-file" element={<UploadFile/>}/> */}

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/order-list" element={<OrderList />} />
          <Route path="/user-list" element={<UserList />} />
          <Route path="/language-price" element={<LanguageList />} />
          <Route path="/topics" element={<TopicList />} />
          <Route path="/order/detail" element={<OrderDetails />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;
