import api from '../../../utils/Api';
import { config } from "../../../utils/EndPoints";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Create a new topic
const createTopic = createAsyncThunk('topic/createTopic', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(config.endPoints.createTopic, data);
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while creating topic: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to create topic." });
  }
});

// Fetch all topics
const getTopics = createAsyncThunk('topic/getTopics', async ({page,search = "", limit}, { rejectWithValue }) => {
  try {
    const response = await api.get(config.endPoints.getTopics, { params: { page, search, limit } });
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while getting topics: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to fetch topics." });
  }
});

// Update a topic
const updateTopic = createAsyncThunk('topic/updateTopic', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await api.patch(`${config.endPoints.updateTopic}/${id}`, data);
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while updating topic: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to update topic." });
  }
});

// Delete a topic by ID
const deleteTopicById = createAsyncThunk('topic/deleteTopicById', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${config.endPoints.deleteSingleTopic}/${id}`);
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while deleting topic: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to delete topic." });
  }
});

export { getTopics, createTopic, deleteTopicById, updateTopic };
