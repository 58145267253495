import Breadcrumb from "../../components/Breadcrumbs/Breadcrumb";
import DefaultLayout from "../../layout/DefaultLayout";
import Table from "../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTopics, deleteTopicById } from "../../redux/feature/topic/topic.service";
import { openModal } from "../../redux/feature/modal/modal.slice";
import CreateTopic from "./CreateTopic";
import UpdateTopic from "./UpdateTopic";
import GlobalModal from "../../components/GlobalModal/GlobalModal";
import Swal from "sweetalert2";
import SearchBar from "../../components/SearchBar/SearchBar"; // Import SearchBar
import Pagination from "../../components/Pagination/Pagination"; // Import Pagination
import Spinner from "../../components/Spinner/Spinner"; // Import Spinner

const TopicList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const columns = [
    { title: "Topic Name", field: "topic", span: 1 },
    { title: "Action", field: "action", span: 1 },
  ];

  // Loading and data from Redux store
  const isLoading = useSelector((state) => state.loading[getTopics.typePrefix]);
  const topicData = useSelector((state) => state?.topic?.topics?.topics?.topics);
  const totalPages = useSelector((state) => state?.topic?.topics?.topics?.pages);
  const totalRecords = useSelector((state) => state?.topic?.topics?.topics?.total);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(getTopics({ page: currentPage, limit: itemsPerPage, search: searchQuery }));
    }, 1000);

    return () => clearTimeout(handler);
  }, [dispatch, currentPage, itemsPerPage, searchQuery]);

  // Function to create a new topic using modal
  const createTopic = () => {
    dispatch(openModal({ componentName: CreateTopic, componentProps: { currentPage: currentPage, itemsPerPage: itemsPerPage }}));
  };

  // Function to update an existing topic using modal
  const updateTopic = (item) => {
    dispatch(openModal({ componentName: UpdateTopic, componentProps: {item: item, currentPage: currentPage, itemsPerPage: itemsPerPage } }));
  };

  // Function to handle deleting a topic by ID
  const handleDeleteTopicById = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTopicById(id))
          .then(() => {
            Swal.fire("Deleted!", "Topic deleted successfully", "success");
            dispatch(getTopics({ page: currentPage, limit: itemsPerPage, search: searchQuery }));
          })
          .catch((error) => {
            Swal.fire("Error!", error?.response?.data?.message || "Failed to delete topic", "error");
          });
      }
    });
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Topics" />

      <div className="flex justify-end">
        <button 
          className="px-4 py-2 text-nbase text-white bg-[#2E8F96] rounded"
          onClick={createTopic}
        >
          Create
        </button>
      </div>

      <div className="py-4 gap-3 flex flex-col sm:flex-row justify-between items-center space-x-4">
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : totalRecords > 0 ? (
        <Table
          cssClassName={''}
          columns={columns}
          data={topicData}
          onEdit={updateTopic}
          onDelete={handleDeleteTopicById}
        />
      ) : (
        <div className="bg-white flex justify-center items-center min-h-[300px] md:min-h-[600px]">
          <p className="font-bold text-center">No Record found</p>
        </div>
      )}

      {totalPages > 1 && (
        <div className="p-4 sm:p-6 xl:p-7.5 flex justify-end">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      )}

    </DefaultLayout>
  );
};

export default TopicList;
