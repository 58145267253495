import api from '../../../utils/Api';
import { config } from "../../../utils/EndPoints";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Create a new target language
const createTargetLanguage = createAsyncThunk('targetLanguage/createTargetLanguage', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(config.endPoints.createTargetLanguage, data);
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while creating target language: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to create target language." });
  }
});

// Fetch all target languages
const getTargetLanguages = createAsyncThunk('targetLanguage/getTargetLanguages', async ({page,search = "", limit}, { rejectWithValue }) => {
  try {
    const response = await api.get(config.endPoints.getTargetLanguages, { params: { page, search, limit } });
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while getting target languages: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to fetch target languages." });
  }
});

// Update a target language
const updateTargetLanguage = createAsyncThunk('targetLanguage/updateTargetLanguage', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await api.patch(`${config.endPoints.updateTargetLanguage}/${id}`, data);
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while updating target language: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to update target language." });
  }
});

// Delete a target language by ID
const deleteTargetLanguageById = createAsyncThunk('targetLanguage/deleteTargetLanguageById', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${config.endPoints.deleteTargetLanguageById}/${id}`);
    return response?.data; // Return the success response
  } catch (error) {
    console.error("Error while deleting target language: ", error);
    return rejectWithValue(error.response?.data || { message: "Failed to delete target language." });
  }
});

export { getTargetLanguages, createTargetLanguage, deleteTargetLanguageById, updateTargetLanguage };
