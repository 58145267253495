import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/Breadcrumbs/Breadcrumb";
import DefaultLayout from "../../layout/DefaultLayout";
import Table from "../../components/Table/Table";
import { getTargetLanguages, deleteTargetLanguageById } from "../../redux/feature/targetLanguage/targetLanguage.service";
import { openModal } from "../../redux/feature/modal/modal.slice";
import CreateLanguage from "./CreateLanguage";
import UpdateLanguage from "./UpdateLanguage";
import GlobalModal from "../../components/GlobalModal/GlobalModal";
import Swal from "sweetalert2";
import SearchBar from "../../components/SearchBar/SearchBar";
import Pagination from "../../components/Pagination/Pagination";
import Spinner from "../../components/Spinner/Spinner";

const LanguageList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Loading and data from Redux store
  const isLoading = useSelector((state) => state.loading[getTargetLanguages.typePrefix]);
  const languageData = useSelector((state) => state.targetLanguage.targetLanguages.targetLanguages);
  const totalPages = useSelector((state) => state.targetLanguage.targetLanguages.pages);
  const totalRecords = useSelector((state) => state.targetLanguage.targetLanguages.total);

  // Table columns definition
  const columns = [
    { title: "Language Name", field: "targetLanguages", span: 1 },
    { title: "Price", field: "price", span: 1 },
    { title: "Action", field: "action", span: 1 }
  ];

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(getTargetLanguages({ page: currentPage, limit: itemsPerPage, search: searchQuery }));
    }, 1000);

    return () => clearTimeout(handler);
  }, [dispatch, currentPage, itemsPerPage, searchQuery]);

  const createTargetLanguage = () => {
    dispatch(openModal({ componentName: CreateLanguage, componentProps: { currentPage: currentPage, itemsPerPage: itemsPerPage } }));
  };

  const updateTargetLanguage = (item) => {
    console.log("id: ", item);
    dispatch(openModal({ componentName: UpdateLanguage, componentProps: {item: item, currentPage: currentPage, itemsPerPage: itemsPerPage} }));
  };

  const handleDeleteLanguageById = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTargetLanguageById(id))
          .then(() => {
            Swal.fire("Deleted!", "Language deleted successfully", "success");
            dispatch(getTargetLanguages({ page: currentPage, limit: itemsPerPage, search: searchQuery }));
          })
          .catch((error) => {
            Swal.fire("Error!", error.message, "error");
          });
      }
    });
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Language/Price" />

      <div className="flex justify-end">
        <button 
          className="px-4 py-2 text-nbase text-white bg-[#2E8F96] rounded"
          onClick={createTargetLanguage}
        >
          Create
        </button>
      </div>

      <Table
        cssClassName={''}
        columns={columns}
        data={languageData}
        onEdit={updateTargetLanguage}
        onDelete={handleDeleteLanguageById}
      />

      <GlobalModal/>
      
    </DefaultLayout>
  );
};

export default LanguageList;
