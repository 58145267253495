/* eslint-disable react/prop-types */
import { useState, useRef } from "react";
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { getOrders, uploadResendDoc } from "../../redux/feature/order/order.service";
import { closeModal } from "../../redux/feature/modal/modal.slice";

const UploadFile = (props) => {

  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null); // Reference to the file input

  const fileUploadLoading = useSelector((state) => state?.loading[uploadResendDoc.typePrefix]);

  // Handle file selection
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    addFiles(files);
    fileInputRef.current.value = ''; // Reset the input value to allow re-upload of the same file
  };

  // Handle drag over
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    addFiles(files);
  };

  // Function to add files and check for duplicates
  const addFiles = (files) => {
    const fileNames = selectedFiles.map((file) => file.name);

    files.forEach((file) => {
      if (fileNames.includes(file.name)) {
        Swal.fire({
          icon: 'warning',
          title: 'File Already Exists',
          text: `The file "${file.name}" has already been selected.`,
        });
      } else {
        setSelectedFiles((prevFiles) => [...prevFiles, file]);
      }
    });
  };

  // Handle file deletion
  const handleDeleteFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };


  // console.log("PROPS: ", props.propData._id)
  
  const uploadFiles = async (_id) => {
  
    const formData = new FormData();
    
    selectedFiles.forEach((file) => {
      formData.append("file", file);
    });
  
    // Pass _id and formData as an object
    await dispatch(uploadResendDoc({ orderId : _id, formData }))
    .unwrap()
    .then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Files uploaded successfully!',
        text: 'Your files have been sent for resend.',
      }).then(() => {
        dispatch(closeModal())
        
        dispatch(getOrders({page: props.page, limit: props.limit}))
      })
    })
    .catch((error) => {
      console.error("Error while uploading a file: ", error);
      Swal.fire({
        icon: 'error',
        title: 'File upload failed',
        text: 'There was an error uploading your files.',
      });
    }) 
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-100 h-fit py-2 bg-gray-50">
      <div
        className="w-full max-w-xl p-4 bg-white border-2 border-dashed border-gray-300 rounded-lg cursor-pointer transition-all hover:bg-gray-100 relative"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center justify-center h-52">
          <CloudArrowUpIcon className="w-14 h-14 text-gray-400" />
          <p className="mt-2 text-sm text-gray-500">Drag & drop a file here, or click to select one</p>

          {/* File input */}
          <input
            type="file"
            multiple
            className="absolute inset-0 opacity-0 cursor-pointer"
            onChange={handleFileChange}
            ref={fileInputRef} // Use the ref to reset the input value
            style={{ pointerEvents: 'auto' }}
          />
        </div>
      </div>

      {/* List of uploaded files */}
      {selectedFiles.length > 0 && (
        <div className="w-full max-w-xl mt-4 space-y-4">
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-3 bg-white rounded-lg shadow-md border border-gray-200"
            >
              <span className="text-gray-700">{file.name}</span>
              <button
                type="button"
                onClick={() => handleDeleteFile(index)}
                className="inline-flex items-center px-2 py-1 text-xs text-red-500 border border-red-500 rounded hover:bg-red-500 hover:text-white"
              >
                <TrashIcon className="w-4.5 h-4.5" />
              </button>
            </div>
          ))}

          {/* Submit button */}
          <div className="flex justify-end">
            <button
              type="button"
              className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              onClick={() => uploadFiles(props.propData._id)}
            >
              {
                fileUploadLoading ? 'Submitting...' : 'Submit'
              }
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFile;